// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-definition-cards-js": () => import("./../../../src/pages/definition-cards.js" /* webpackChunkName: "component---src-pages-definition-cards-js" */),
  "component---src-pages-exercise-sheets-js": () => import("./../../../src/pages/exercise-sheets.js" /* webpackChunkName: "component---src-pages-exercise-sheets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-revision-notes-js": () => import("./../../../src/pages/revision-notes.js" /* webpackChunkName: "component---src-pages-revision-notes-js" */),
  "component---src-pages-vocabulary-cards-js": () => import("./../../../src/pages/vocabulary-cards.js" /* webpackChunkName: "component---src-pages-vocabulary-cards-js" */),
  "component---src-templates-exercise-sheet-template-js": () => import("./../../../src/templates/exercise-sheet-template.js" /* webpackChunkName: "component---src-templates-exercise-sheet-template-js" */),
  "component---src-templates-revision-note-template-js": () => import("./../../../src/templates/revision-note-template.js" /* webpackChunkName: "component---src-templates-revision-note-template-js" */)
}

